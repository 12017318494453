import React, { useState, useEffect, useCallback } from 'react';

const Timer = ({ selectedTask, onUpdateTask }) => {
  const [timerType, setTimerType] = useState('work');
  const [minutes, setMinutes] = useState(25);
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [pomodoros, setPomodoros] = useState(0);

  const resetTimer = useCallback(() => {
    switch (timerType) {
      case 'work':
        setMinutes(25);
        break;
      case 'shortBreak':
        setMinutes(5);
        break;
      case 'longBreak':
        setMinutes(10);
        break;
      default:
        setMinutes(25);
    }
    setSeconds(0);
  }, [timerType]);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          clearInterval(interval);
          setIsActive(false);
          if (timerType === 'work') {
            setPomodoros(pomodoros + 1);
            if (selectedTask) {
              onUpdateTask(selectedTask.id, selectedTask.completed_pomos + 1);
            }
            alert("Pomodoro complete! Take a break.");
          } else {
            alert("Break time is over. Start your next Pomodoro!");
          }
          resetTimer();
        }
      }, 1000);
    } else if (!isActive && (seconds !== 0 || minutes !== 0)) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, minutes, seconds, timerType, pomodoros, selectedTask, onUpdateTask, resetTimer]);

  const toggleTimer = () => {
    setIsActive(!isActive);
  };

  const stopTimer = () => {
    setIsActive(false);
    resetTimer();
  };

  const changeTimerType = (type) => {
    setTimerType(type);
    setIsActive(false);
    switch (type) {
      case 'work':
        setMinutes(25);
        break;
      case 'shortBreak':
        setMinutes(5);
        break;
      case 'longBreak':
        setMinutes(10);
        break;
      default:
        setMinutes(25);
    }
    setSeconds(0);
  };

  return (
    <div className={`container timer ${timerType}`}>
      <div className="timer-tabs">
        <button 
          className={timerType === 'work' ? 'active' : ''} 
          onClick={() => changeTimerType('work')}
        >
          Work
        </button>
        <button 
          className={timerType === 'shortBreak' ? 'active' : ''} 
          onClick={() => changeTimerType('shortBreak')}
        >
          Short Break
        </button>
        <button 
          className={timerType === 'longBreak' ? 'active' : ''} 
          onClick={() => changeTimerType('longBreak')}
        >
          Long Break
        </button>
      </div>
      {selectedTask && (
        <div className="selected-task">
          Current Task: {selectedTask.task} ({selectedTask.completed_pomos}/{selectedTask.pomos} pomos)
        </div>
      )}
      <div className="time-display">
        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
      </div>
      {!isActive ? (
        <button type="submit" onClick={toggleTimer}>Start</button>
      ) : (
        <div>
          <button onClick={toggleTimer}>Pause</button>
          <button onClick={stopTimer}>Stop</button>
        </div>
      )}
    </div>
  );
};

export default Timer;