import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { createClient } from '@supabase/supabase-js';
import Login from './components/Login';
import Timer from './components/Timer';
import TodoList from './components/TodoList';
import DarkModeToggle from './components/DarkModeToggle';
import './App.css';

// Add console.log statements to check environment variables
console.log('Supabase URL:', process.env.REACT_APP_SUPABASE_URL);
console.log('Supabase Key available:', !!process.env.REACT_APP_SUPABASE_KEY);

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_KEY);

function App() {
  const [session, setSession] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  const getMetadata = () => {
    const baseTitle = "Pomodorooo Timer with ToDo List | Boost Your Productivity with Deep Work";
    const baseDescription = "Enhance your work efficiency with Pomodorooo. Customizable work sessions, break timers, and an integrated ToDo list for optimal productivity.";
    
    if (!session) {
      return {
        title: `Login - ${baseTitle}`,
        description: `Sign in to access your personalized ${baseDescription}`
      };
    }
    
    return {
      title: `My Workspace - ${baseTitle}`,
      description: `Welcome back! Continue boosting your productivity with ${baseDescription}`
    };
  };

  const { title, description } = getMetadata();

  const handleSelectTask = useCallback((task) => {
    setSelectedTask(task);
  }, []);

  const handleUpdateTask = useCallback(async (taskId, completedPomos) => {
    try {
      const { error } = await supabase
        .from('todos')
        .update({ completed_pomos: completedPomos })
        .eq('id', taskId)
        .select()
        .single();

      if (error) throw error;

      setSelectedTask(prevTask => 
        prevTask && prevTask.id === taskId 
          ? { ...prevTask, completed_pomos: completedPomos }
          : prevTask
      );
    } catch (error) {
      console.error('Error updating task:', error);
    }
  }, []);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      // The session will be automatically set to null by the onAuthStateChange listener
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
      </Helmet>

      {!session ? (
        <Login supabase={supabase} />
      ) : (
        <div className="app-content">
          <h1>Pomodorooo</h1>
          <Timer 
            selectedTask={selectedTask} 
            onUpdateTask={handleUpdateTask}
          />
          <TodoList 
            supabase={supabase} 
            userId={session.user.id} 
            onSelectTask={handleSelectTask}
          />
          <footer>
            <div className="user-info">
              <span className="user-email">{session.user.email}</span>
              <button onClick={handleLogout} className="logout-button">Logout</button>
            </div>
          </footer>
        </div>
      )}
      <DarkModeToggle darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
    </div>
  );
}

export default App;