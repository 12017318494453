import React, { useState, useEffect, useCallback } from 'react';
import './TodoList.css';

const TodoList = ({ supabase, userId, onSelectTask }) => {
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState('');
  const [newPomos, setNewPomos] = useState(1);
  const [newStatus, setNewStatus] = useState('medium');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [editingTodo, setEditingTodo] = useState(null);

  const fetchTodos = useCallback(async () => {
    setIsLoading(true);
    setError('');
    try {
      const { data, error } = await supabase
        .from('todos')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false }); // Changed to descending order

      if (error) throw error;
      setTodos(data);
    } catch (error) {
      setError('Failed to fetch todos. Please try again.');
      console.error('Error fetching todos:', error);
      logErrorDetails(error);
    } finally {
      setIsLoading(false);
    }
  }, [supabase, userId]);

  useEffect(() => {
    fetchTodos();
  }, [fetchTodos]);

  const addTodo = async (e) => {
    e.preventDefault();
    if (newTodo.trim() === '') {
      setError('Task cannot be empty');
      return;
    }
    setIsLoading(true);
    setError('');

    const newTodoItem = { 
      task: newTodo, 
      pomos: newPomos, 
      completed_pomos: 0,
      status: newStatus,
      user_id: userId, 
      id: Date.now() 
    };
    setTodos([newTodoItem, ...todos]); // Changed to add new item at the beginning

    try {
      const { data, error } = await supabase
        .from('todos')
        .insert([{ 
          task: newTodo, 
          pomos: newPomos, 
          completed_pomos: 0,
          status: newStatus,
          user_id: userId 
        }])
        .select();

      if (error) throw error;
      setTodos(todos => [data[0], ...todos.filter(todo => todo.id !== newTodoItem.id)]); // Updated to maintain new order
      setNewTodo('');
      setNewPomos(1);
      setNewStatus('medium');
    } catch (error) {
      setError('Failed to add todo. Please try again.');
      setTodos(todos => todos.filter(todo => todo.id !== newTodoItem.id)); // Revert optimistic update
      console.error('Error adding todo:', error);
      logErrorDetails(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTodo = async (id) => {
    setIsLoading(true);
    setError('');
    const todoToDelete = todos.find(todo => todo.id === id);
    setTodos(todos => todos.filter(todo => todo.id !== id)); // Optimistic update

    try {
      const { error } = await supabase
        .from('todos')
        .delete()
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      setError('Failed to delete todo. Please try again.');
      setTodos(todos => [todoToDelete, ...todos.filter(todo => todo.id !== todoToDelete.id)]); // Revert optimistic update
      console.error('Error deleting todo:', error);
      logErrorDetails(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateTodoProgress = async (id, completedPomos) => {
    try {
      const { error } = await supabase
        .from('todos')
        .update({ completed_pomos: completedPomos })
        .eq('id', id);

      if (error) throw error;
      setTodos(todos => todos.map(todo => 
        todo.id === id ? { ...todo, completed_pomos: completedPomos } : todo
      ));
    } catch (error) {
      console.error('Error updating todo progress:', error);
      logErrorDetails(error);
    }
  };

  const startEditing = (todo) => {
    setEditingTodo({ ...todo });
  };

  const saveEdit = async () => {
    if (!editingTodo) return;

    try {
      const { error } = await supabase
        .from('todos')
        .update({
          task: editingTodo.task,
          pomos: editingTodo.pomos,
          status: editingTodo.status
        })
        .eq('id', editingTodo.id);

      if (error) throw error;

      setTodos(todos => todos.map(todo => 
        todo.id === editingTodo.id ? editingTodo : todo
      ));
      setEditingTodo(null);
    } catch (error) {
      console.error('Error updating todo:', error);
      logErrorDetails(error);
    }
  };

  const cancelEdit = () => {
    setEditingTodo(null);
  };

  const getStatusSymbol = (status) => {
    switch (status) {
      case 'high':
        return '+';
      case 'low':
        return '-';
      default:
        return '○';
    }
  };

  const formatPomos = (completed, total) => {
    const completedPomos = completed || 0;  // Use 0 if completed is undefined
    return `${completedPomos}/${total} pomo${total > 1 ? 's' : ''}`;
  };

  const logErrorDetails = (error) => {
    console.error('Error details:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    if (error.message) {
      console.error('Error message:', error.message);
    }
    if (error.details) {
      console.error('Error details:', error.details);
    }
    if (error.hint) {
      console.error('Error hint:', error.hint);
    }
  };

  const handlePomoIncrement = (todo) => {
    const newCompletedPomos = Math.min(todo.completed_pomos + 1, todo.pomos);
    updateTodoProgress(todo.id, newCompletedPomos);
  };

  const handlePomoDecrement = (todo) => {
    const newCompletedPomos = Math.max(todo.completed_pomos - 1, 0);
    updateTodoProgress(todo.id, newCompletedPomos);
  };

  const isTaskCompleted = (todo) => {
    return todo.completed_pomos === todo.pomos;
  };

  return (
    <div className="container todo-list">
      <h2>ToDo List</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={addTodo}>
        <input
          type="text"
          value={newTodo}
          onChange={(e) => setNewTodo(e.target.value)}
          placeholder="Enter a new task"
          disabled={isLoading}
        />
        <input
          type="number"
          value={newPomos}
          onChange={(e) => setNewPomos(Math.max(1, Math.min(10, parseInt(e.target.value, 10))))}
          min="1"
          max="10"
          disabled={isLoading}
        />
        <select
          value={newStatus}
          onChange={(e) => setNewStatus(e.target.value)}
          disabled={isLoading}
        >
          <option value="high">High</option>
          <option value="medium">Medium</option>
          <option value="low">Low</option>
        </select>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Adding...' : 'Add Task'}
        </button>
      </form>
      {isLoading && <div className="loading-indicator">Loading...</div>}
      <ul>
        {todos.map((todo) => (
          <li key={todo.id}>
            {editingTodo && editingTodo.id === todo.id ? (
              <>
                <input
                  type="text"
                  value={editingTodo.task}
                  onChange={(e) => setEditingTodo({ ...editingTodo, task: e.target.value })}
                />
                <input
                  type="number"
                  value={editingTodo.pomos}
                  onChange={(e) => setEditingTodo({ ...editingTodo, pomos: Math.max(1, Math.min(10, parseInt(e.target.value, 10))) })}
                  min="1"
                  max="10"
                />
                <select
                  value={editingTodo.status}
                  onChange={(e) => setEditingTodo({ ...editingTodo, status: e.target.value })}
                >
                  <option value="high">High</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>
                <button onClick={saveEdit}>Save</button>
                <button onClick={cancelEdit}>Cancel</button>
              </>
            ) : (
              <>
                <span 
                  onClick={() => onSelectTask(todo)}
                  className={isTaskCompleted(todo) ? 'completed-task' : ''}
                >
                  {getStatusSymbol(todo.status)} {todo.task} ({formatPomos(todo.completed_pomos, todo.pomos)})
                </span>
                <button onClick={() => handlePomoDecrement(todo)}>-</button>
                <button onClick={() => handlePomoIncrement(todo)}>+</button>
                <button onClick={() => startEditing(todo)}>Edit</button>
                <button onClick={(e) => { e.stopPropagation(); deleteTodo(todo.id); }} disabled={isLoading}>
                  {isLoading ? 'Deleting...' : 'Delete'}
                </button>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TodoList;